import React from "react";
import "./eng.css";
import two from "../../images/service/two.png";
import three from "../../images/service/three.jpg";
import four from "../../images/service/four.jpg";
import six from "../../images/service/six.jpg";
import five from "../../images/service/five.png";

import Whyanchor from "../../component/whyanchor/Whyanchor";
import { Link } from "react-router-dom";

export default function Engineering() {
  return (
    <div className="engineering">
      <div className="bodyreuse">
        <div className="width">
          <div className="reuse">
            <div className="text bw">
              <p>Synoford Engineering</p>A construction company dedicated to
              ensuring that its customers are satisfied with the end results of
              the projects it takes on. We believe that every new project we
              undertake is an opportunity to make a difference, so we approach
              each project with the expertise and commitment it requires.
            </div>
            <div className="pic">
              <img src={three} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="oilreverse">
        <div className="width">
          <div className="reuse reverse">
            <div className="text bw">
              <p></p>
              At Synoford, we handle turnkey construction projects for distinct
              clients; private, corporate and government. And within a short
              amount of time, Synoford has been able to build an excellent
              reputation for itself; we are known for delivering projects on
              time and as specified. This is because customer satisfaction is at
              the heart of all that we do. <br />
              <br />
              We are an organization driven by the endless possibilities of
              tomorrow. And our commitment to excellence is certainly paving the
              path for us to stand out amongst others in the construction
              industry
            </div>
            <div className="pic">
              <img src={two} alt="" />
            </div>
          </div>

          <div className="reuse ">
            <div className="text bw">
              <p>PARTNERSHIP</p>
              In order to continue delivering first-rate projects to our ever
              growing clientele, we have entered into a strategic partnership
              with Emrol Nigeria Limited. This partnership ensures that our team
              members are constantly exposed to the best global practices in the
              field of construction which they will put to use on the job.
            </div>
            <div className="pic">
              <img src={four} alt="" />
            </div>
          </div>

          <div className="reuse reverse">
            <div className="text bw">
              <p>PROCEDURE</p>
              We start out by sitting down and talking with our clients. We
              spend a great deal of time making sure we understand exactly what
              they want. Then we go ahead to turn their imaginations and desires
              into a workable blueprint. Once we have our blueprint, our clients
              can be rest assured that their projects will be delivered
              according to their specifications, on budget and on time.
            </div>
            <div className="pic">
              <img src={five} alt="" />
            </div>
          </div>

          <div className="reuse ">
            <div className="text bw">
              <p>Professionalism</p>
              To stay true to our goal of satisfying our clients; we ensure that
              we hire bright and smart minds, people with excellent work ethics.
              We are also committed to constantly training our staff to ensure
              that they acquire skill sets that are current and relevant to our
              industry in today’s everchanging world. And if we have to use the
              services of a third party, we go to great lengths to ensure that
              we get the best professionals for the job
              <Link to="/team">
                <div className="aboutus">
                  <button>Our Team</button>
                </div>
              </Link>
            </div>
            <div className="pic">
              <img src={six} alt="" />
            </div>
          </div>
        </div>
      </div>

      <Whyanchor />
    </div>
  );
}
