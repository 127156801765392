import "./footer.css";
import rex from "../../images/homepage/unnamed (1).png";
// import rex from "../../images/photo_2023-04-15_13-15-45.jpg";
import three from "../../images/1.png";
import two from "../../images/2.png";
import one from "../../images/3.png";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer">
      <div className="width">
        <div className="every">
          <div className="logo">
            <div className="image">
              <img src={rex} alt="" />
            </div>
            <p></p>

            <div className="socials">
              <a target="blank" href="https://twitter.com/Synoford_homes">
                <img src={one} alt="" />
              </a>
              <a
                target="blank"
                href="https://www.instagram.com/synoford_homes/"
              >
                <img src={three} alt="" />
              </a>{" "}
              <a href="/">
                <img src={two} alt="" />
              </a>
            </div>
          </div>
          <div className="list">
            <div className="two">
              <ul>
                <Link to="/aboutus">
                  <li> About us</li>
                </Link>
                <Link to="/engineering">
                  <li>Synoford Engineering </li>
                </Link>
                <Link to="/homes">
                  <li>Synoford Homes </li>
                </Link>
                <Link to="/tofro">
                  <li>TO-FRO haulage & Logistics </li>
                </Link>
              </ul>
            </div>
            <div className="two">
              <ul>
                <li>Company</li>
                <Link to="/sustainability">
                  <li>Sustainability </li>
                </Link>
                <Link to="/synofordgallery">
                  <li>Synoford Gallery</li>
                </Link>
                <Link to="/tofrogallery">
                  <li>Tofro Gallery</li>
                </Link>
              </ul>
            </div>
            <div className="two">
              <ul>
                <li>Contact us</li>
                <li>
                  {" "}
                  <a
                    href="https://www.google.ng/maps/place/Standard+Alliance+Building,+1+The+Providence+St,+Lekki+Phase+I+106104,+Lekki,+Lagos/@6.4316033,3.4578114,17z/data=!3m1!4b1!4m6!3m5!1s0x103bf5a9d929eeb7:0x21c218910755a8b9!8m2!3d6.431598!4d3.4603863!16s%2Fg%2F1ptw7mxyl?entry=ttu"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Standard Alliance Building, The Providence St, Lekki Phase I
                    106104, Lekki, Lagos
                  </a>
                </li>
                <li>
                  <a href="tel:07039119356">07039119356</a>
                  <br /> <a href="tel:08137800092">08137800092</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
