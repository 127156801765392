import React from "react";

import three from "../../images/imgg/three.jpg";

import seven from "../../images/imgg/seven.jpg";
import eight from "../../images/imgg/eight.jpg";
import nine from "../../images/imgg/nine.jpg";
import ten from "../../images/imgg/ten.jpg";
import eleven from "../../images/imgg/eleven.jpg";
import fourteen from "../../images/imgg/fourteen.jpg";
import fifteen from "../../images/imgg/fifteen.jpg";
import real1 from "../../images/imgg/real1.jpg";
import real2 from "../../images/imgg/real2.jpg";
import real3 from "../../images/imgg/real3.jpg";
import real4 from "../../images/imgg/real4.jpg";
import real5 from "../../images/imgg/real5.jpg";
import real6 from "../../images/imgg/real6.jpg";
import real7 from "../../images/imgg/real7.jpg";
import "./gallery.css";

export default function Gallery2() {
  return (
    <div className="width">
      <div className="portfolio">
        <div className="gallery2">
          <div className="section-header">
            <h1 class="section-heading">TO-FRO VEHICLES</h1>
          </div>

          <div class="wrapper">
            <div class="card2">
              <div class="card-img-wrapper">
                <img src={real1} alt="CEO" />
              </div>
            </div>

            <div class="card2">
              <div class="card-img-wrapper">
                <img src={real2} alt="Designer" />
              </div>
            </div>
            <div class="card2">
              <div class="card-img-wrapper">
                <img src={three} alt="Architect" />
              </div>
            </div>
            <div class="card2">
              <div class="card-img-wrapper">
                <img src={real3} alt="Architect" />
              </div>
            </div>

            <div class="card2">
              <div class="card-img-wrapper">
                <img src={real4} alt="Architect" />
              </div>
            </div>

            <div class="card2">
              <div class="card-img-wrapper">
                <img src={real5} alt="Architect" />
              </div>
            </div>

            <div class="card2">
              <div class="card-img-wrapper">
                <img src={real6} alt="Architect" />
              </div>
            </div>
            <div class="card2">
              <div class="card-img-wrapper">
                <img src={seven} alt="Architect" />
              </div>
            </div>
            <div class="card2">
              <div class="card-img-wrapper">
                <img src={real7} alt="Architect" />
              </div>
            </div>
            <div class="card2">
              <div class="card-img-wrapper">
                <img src={eight} alt="Architect" />
              </div>
            </div>
            <div class="card2">
              <div class="card-img-wrapper">
                <img src={nine} alt="Architect" />
              </div>
            </div>
            <div class="card2">
              <div class="card-img-wrapper">
                <img src={fifteen} alt="Architect" />
              </div>
            </div>
            <div class="card2">
              <div class="card-img-wrapper">
                <img src={fourteen} alt="Architect" />
              </div>
            </div>
            <div class="card2">
              <div class="card-img-wrapper">
                <img src={ten} alt="Architect" />
              </div>
            </div>
            <div class="card2">
              <div class="card-img-wrapper">
                <img src={eleven} alt="Architect" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
