import React from "react";
import woman from "../../images/about/james-sullivan-ESZRBtkQ_f8-unsplash (1).jpg";
import "./story.css";
import { Link } from "react-router-dom";

export default function Story() {
  return (
    <div className="story">
      <div className="width">
        <div className="image">
          <img src={woman} alt="" />
        </div>
        <div className="talk">
          <p>
            <span>Synoford Engineering </span>
            is a construction company dedicated to ensuring that its customers
            are satisfied with the end results of the projects we take on.{" "}
            <br />
            <br /> We believe that every new project we undertake is an
            opportunity to make a difference, so we approach each project with
            the expertise and commitment it requires.
          </p>
          <Link to="/engineering">
            <div className="aboutus">
              <button>Read more </button>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
