import React from "react";
import "./reusebody.css";

export default function Reusebody() {
  return (
    <div className="reusebody">
      <div className="content">
        <p>
          Creating <span>beautiful spaces </span> <br /> in Africa.
        </p>
        <p>
          Real estate requires careful consideration of design, natural light,
          high-quality materials, and functionality. These factors can enhance
          the property's appeal, value, and attract potential buyers or renters.
        </p>
      </div>
    </div>
  );
}
