import React from "react";
import "./sustainability.css";
import first from "../../images/about/emmanuel-ikwuegbu-j4wewSTp2GY-unsplash (1).jpg";
import second from "../../images/about/agefis-qh-mar1Tzo8-unsplash (1).jpg";
import third from "../../images/about/ernesto-leon-4H_I-HRjkyA-unsplash (2).jpg";

export default function Sustainability() {
  return (
    <div className="sustainability">
      <div className="home about">
        <div className="width">
          <div className="reusebody">
            <div className="content">
              <p>Sustainability</p>
              <p>
                We are committed to operating our business in a way that is
                <span>
                  {" "}
                  environmentally responsible, socially equitable, and
                  economically viable
                </span>
                . Our sustainability practices are an integral part of our
                business strategy and we are continuously striving to improve
                our performance.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg">
        <div className="width">
          <div className="story reverse">
            <div className="width">
              <div className="image">
                <img src={second} alt="" />
              </div>
              <div className="talk">
                <p>
                  <span className="span">Environmental Sustainability </span>{" "}
                  <br />
                  <br /> We understand that buildings have a significant impact
                  on the environment and we are committed to minimizing our
                  carbon footprint. We have implemented certain building design
                  principles in our properties to reduce energy and water
                  consumption, improve indoor air quality, and reduce waste. We
                  also promote the use of sustainable materials in construction
                  and renovation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="width">
        <div className="story">
          <div className="width">
            <div className="image">
              <img src={first} alt="" />
            </div>
            <div className="talk">
              <p>
                <span className="span">Social Sustainability </span> <br />
                <br /> We believe that real estate should be accessible to
                everyone and we are committed to promoting equitable access to
                housing. We work to ensure that our properties are safe,
                healthy, and comfortable for our tenants. Additionally, we
                engage with our local communities to address social issues and
                support community initiatives.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg">
        <div className="width">
          <div className="story reverse">
            <div className="width">
              <div className="image">
                <img src={third} alt="" />
              </div>
              <div className="talk">
                <p>
                  <span className="span">Economic Sustainability </span> <br />
                  <br /> We recognize that financial sustainability is essential
                  for the long-term success of our business. We invest in our
                  properties to improve their value and longevity while
                  minimizing operating costs. By implementing energy-efficient
                  practices, we are able to reduce energy consumption and lower
                  utility costs, which benefits our tenants and the environment.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="width">
        <div className="story">
          <div className="width">
            <div className="image">
              <img src={first} alt="" />
            </div>
            <div className="talk">
              <p>
                <span className="span">Sustainability Reporting </span> <br />
                <br /> We are transparent about our sustainability practices and
                report on our performance regularly. Our annual sustainability
                report outlines our progress towards our sustainability goals,
                and we use the information to identify areas for improvement.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg">
        <div className="width">
          <div className="story reverse">
            <div className="width">
              <div className="image">
                <img src={third} alt="" />
              </div>
              <div className="talk">
                <p>
                  At our real estate business, we believe that sustainability is
                  a critical component of our success, and we are committed to
                  operating our business in a way that promotes environmental,
                  social, and economic sustainability. Thank you for joining us
                  on this journey towards a more sustainable future.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
