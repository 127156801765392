import React, { useState } from "react";
import "./contactus.css";

export default function Contactus() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [Phonenumber, setPhonenumber] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      name,
      email,
      message,
      Phonenumber,
    };

    const response = await fetch("https://formspree.io/f/xjvdleav", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      alert("Your message has been sent, thank you!");
    } else {
      alert("Sorry, there was an error. Please try again .");
    }
  };

  return (
    <>
      <div className="home about">
        <div className="width">
          <div className="reusebody">
            <div className="content">
              <p>Contact Us</p>
              <p></p>
            </div>
          </div>
        </div>
      </div>
      <section id="contact">
        <div className="width">
          <div class="container">
            <div class="flexer">
              <div id="form-container">
                <h3>Contact Form</h3>
                <form onSubmit={handleSubmit}>
                  <label for="name">Name</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    onChange={(e) => setName(e.target.value)}
                    required
                  />

                  <label for="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />

                  <label for="subject">Phone Number</label>
                  <input
                    type="text"
                    name="phonenumber"
                    id="phonenumber"
                    onChange={(e) => setPhonenumber(e.target.value)}
                    required
                  />

                  <label for="message">Message</label>
                  <textarea
                    name="message"
                    id="message"
                    placeholder=" Write your message here"
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  ></textarea>

                  <input type="submit" value="Submit" />
                </form>
              </div>
              <div id="address-container">
                <label>Address</label>
                <address>
                  21B, Chris Madueke Drive Off Doherty Adebayo Street Lekki
                  Phase 1, Lagos State
                </address>

                <label>Phone</label>
                <a href="/">07039119356</a>

                <label>Email</label>

                <a href="mailto:info@synofordengineering.com">
                  info@synofordengineering.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
