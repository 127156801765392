import React from "react";
import Reusebody from "../../component/reusebody/Reusebody";
import Story from "../../component/story/Story";
import Vision from "../../component/vision/Vision";
import first from "../../images/about/emmanuel-ikwuegbu-j4wewSTp2GY-unsplash (1).jpg";
import second from "../../images/about/agefis-qh-mar1Tzo8-unsplash (1).jpg";
import third from "../../images/about/ernesto-leon-4H_I-HRjkyA-unsplash (2).jpg";
import { Link } from "react-router-dom";

export default function About() {
  return (
    <div className="about">
      <div className="home about">
        <div className="width">
          <Reusebody />
        </div>
      </div>
      <Story />

      <Vision />
      <div className="bg">
        <div className="width">
          <div className="story reverse">
            <div className="width">
              <div className="image">
                <img src={second} alt="" />
              </div>
              <div className="talk">
                <p>Our Principles</p>
                <div className="list">
                  <div className="two">
                    <i class="fa-solid fa-house"></i>
                    <p>
                      <span> Integrity:</span> To be unconditionally true in all
                      our dealings
                    </p>
                  </div>
                  <div className="two">
                    <i class="fa-solid fa-bowl-food"></i>
                    <p>
                      <span> Excellence: </span> To always aim for the best
                    </p>
                  </div>

                  <div className="two">
                    <i class="fa-sharp fa-solid fa-sitemap"></i>
                    <p>
                      <span>Innovation: </span>
                      To encourage new ideas and methods in our organization
                    </p>
                  </div>

                  <div className="two">
                    <i class="fa-solid fa-stapler"></i>
                    <p>
                      <span>Possibility: </span>
                      To maintain a “can do” attitude at all times
                    </p>
                  </div>
                  <div className="two">
                    <i class="fa-solid fa-address-card"></i>
                    <p>
                      <span>Empathy: </span> To address & understand customer
                      doubts/concerns.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="width">
        <div className="story">
          <div className="width">
            <div className="image">
              <img src={first} alt="" />
            </div>
            <div className="talk">
              <p>
                <span>Synoford Homes </span> is the finishing and Interior Décor
                arm of Synoford Engineering Nig. Ltd. <br />
                <br />
                Our Company is dedicated to ensuring that its customers are
                satisfied with the end results of the projects we take on.
              </p>

              <div className="aboutus">
                <Link to="/homes">
                  <button>Read more </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg">
        <div className="width">
          <div className="story reverse">
            <div className="width">
              <div className="image">
                <img src={third} alt="" />
              </div>
              <div className="talk">
                <p>
                  <span> To-fro-dotcom nigeria limited </span> is a full service
                  logistics company incorporated in 2015 with the vision to
                  provide a one stop solution of all logistics and transport
                  related services in an efficient and timeless manner.
                  <br />
                  <br />
                  At <span> To-fro-dotcom nigeria limited </span> , we have
                  established a reputation of trust and goodwill among our few
                  prestigious clients and aspire to become a market leader in
                  the Logistics industry.
                </p>

                <div className="aboutus">
                  <Link to="/tofro">
                    <button>Read more </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
