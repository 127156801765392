import "./vision.css";
import glass from "../../images/homepage/Frame.png";
import flag from "../../images/homepage/Group.png";

export default function Vision() {
  return (
    <>
      {" "}
      <div className="vision">
        <div className="width">
          <div className="mission">
            <img src={glass} alt="" />
            <p>OUR VISION</p>
            <p>
              To become the construction company of first choice for
              individuals, corporations and governments in West Africa and to
              help provide high quality services that combine performance with
              value pricing, while establishing a successful relationship with
              our customers.
            </p>
          </div>
          <div className="mission">
            <img src={flag} alt="" />
            <p>OUR MISSION</p>
            <p>
              To deliver projects to our clients that are top-notch and to
              maintain 100% client satisfaction using the most cost effective
              options
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
