import "./whyanchor.css";
import one from "../../images/service/one.jpg";
import icon6 from "../../images/i6.svg";
import icon7 from "../../images/i7.svg";
import icon8 from "../../images/i8.svg";
import icon9 from "../../images/i9.svg";
import icon10 from "../../images/i10.svg";
import icon11 from "../../images/i11.svg";

export default function Whyanchor() {
  return (
    <div className="whyanchor padding">
      <div className="width">
        <div className="firstset">
          <p></p>
          <p>Categories of client and market expertise</p>
          <p>
            We typically deal with a wide range of clients, each with their own
            unique needs and requirements. Overall, the category of clients for
            synoford engineering is quite diverse, and the specific types of
            clients that we work with will depend on their particular area of
            focus and expertise.
          </p>
        </div>
        <div className="secondset">
          <div v className="build">
            <img src={one} alt="" />
          </div>
          <div className="build">
            <div className="icontext">
              <div className="icon">
                <img src={icon7} alt="" />
              </div>
              <div className="text">
                <p>Private</p>
                <p>
                  We build dream homes for individuals and families, helping to
                  turn their fantasies into realities. When dealing with
                  families, we take into account the wants and needs of each
                  family member and work it all into the final deliverable
                </p>
              </div>
            </div>
            <div className="icontext">
              <div className="icon">
                <img src={icon6} alt="" />
              </div>
              <div className="text">
                <p>Commercial</p>
                <p>
                  Whether it is a purpose-built office facility or just a
                  general office complex for hire, we put a lot into ensuring
                  that the work place is created to suit the requirements, wants
                  and needs of our clients
                </p>
              </div>
            </div>
            <div className="icontext">
              <div className="icon">
                <img src={icon8} alt="" />
                {/* <i class="fa-sharp fa-solid fa-landmark"></i> */}
              </div>
              <div className="text">
                <p>Industrial</p>
                <p>
                  We design and build to the specific need of your industry,
                  taking into consideration the scope and nature of your
                  business.
                </p>
              </div>
            </div>
            <div className="icontext">
              <div className="icon">
                <img src={icon9} alt="" />
              </div>
              <div className="text">
                <p>Infrastructural</p>
                <p>
                  We handle construction of roads, bridges and utilities for
                  corporate organizations and governments agencies, ensuring
                  that we deliver to global standards
                </p>
              </div>
            </div>
            <div className="icontext">
              <div className="icon">
                <img src={icon10} alt="" />
              </div>
              <div className="text">
                <p>Scalable & Secure</p>
                <p>
                  Our secure platform scales with you and is trusted by
                  developers, startups, and large corporates.
                </p>
              </div>
            </div>
            <div className="icontext">
              <div className="icon">
                <img src={icon11} alt="" />
              </div>
              <div className="text">
                <p>Build a complete solution</p>
                <p>
                  Our fully integrated platform gives you the flexibility to
                  build all the financial features as you require.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
