import React from "react";
import "./bodycontent.css";

export default function Bodycontent() {
  return (
    <div className="bodycontent">
      <div className="width">
        <div className="talk">
          <div className="t">
            <p>A Brand New Journey</p>
            <p>
              We want to deliver projects to our clients that are top-notch and
              maintain 100% client satisfaction using the most cost effective
              options
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
