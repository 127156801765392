import React from "react";
import third from "../../images/person-1.jpg";

export default function Team() {
  return (
    <div className="sustainability">
      <div className="home about">
        <div className="width">
          <div className="reusebody">
            <div className="content">
              <p>Our Team</p>
              <p>
                Our team is dedicated to helping you achieve your real estate
                goals with{" "}
                <span>
                  expert guidance, open communication, and up-to-date knowledge.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg">
        <div className="width">
          <div className="story reverse">
            <div className="width">
              <div className="image">
                <img src={third} alt="" />
              </div>
              <div className="talk">
                <p>
                  <span className="span">Saide Yusuf, MD/CEO</span> <br />
                  <br /> Saide Yusuf is a graduate of Mechanical Engineering. He
                  is a seasoned engineer / contractor with over fifteen years of
                  experience in the construction industry both within and
                  outside the shores of Nigeria. He has overseen several key
                  road and building projects across different parts of the
                  country. Saide is known as an advocate for excellence and well
                  known for his philanthropic gesture. Saide continues to make a
                  substantial impact in all the communities he has invested.
                  <br />
                  <br />
                  <span className="oya">
                    Email:
                    <a href="mailto:saide.yc@synofordengineering.com">
                      saide.yc@synofordengineering.com
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
