import "./banking.css";
import code from "../../images/service/one.jpg";
import stroke from "../../images/service/Stroke 1.svg";
// import funds from "../../images/service/Funds-Transfer.gif";
// import arrow from "../../images/service/arrow.svg";
import { useState } from "react";

export default function Banking() {
  const [active, setactive] = useState(true);
  return (
    <div className="banking padding">
      <div className="width">
        <div className="content reusecontent">
          <p className="dev"></p>
          <p>Commitment to excellence</p>
          <p>
            The company strives to satisfy its customers by approaching each
            project with expertise and commitment, utilizing the best materials
            and maintaining industry standards, which has helped the company
            stand out amongst others.
          </p>
          <div className="haste">
            <div className="acc">
              <div onClick={() => setactive(true)} className="first">
                <p>Company's dedication</p>
                <img className={active ? "roll" : ""} src={stroke} alt="" />
              </div>
              <div className={active ? "second ag" : "second"}>
                The company is dedicated to satisfying customers by approaching
                each project with expertise, considering every new project an
                opportunity to make a difference.
              </div>
            </div>
            <div className="acc">
              <div onClick={() => setactive(false)} className="first">
                <p>Company's standard</p>
                <img className={active ? "" : "roll"} src={stroke} alt="" />
              </div>
              <div className={active ? "second " : "second ag"}>
                The company's commitment to excellence ensures the use of the
                best materials and adherence to industry standards, making it
                stand out among others.
              </div>
            </div>
          </div>
          <div></div>
        </div>
        <div className="content second">
          <img src={code} alt="" />
        </div>
      </div>
    </div>
  );
}
