import "./header.css";
import React, { useState } from "react";
import logo from "../../images/homepage/unnamed (1).png";
import menu from "../../images/homepage/menub.svg";
import menu2 from "../../images/homepage/close-circle.svg";
import { Link } from "react-router-dom";

export default function Header() {
  const [isActive, setActive] = useState(false);

  const handleToggle = () => {
    setActive(!isActive);
  };
  return (
    <div className="header">
      <div className="width">
        <div className="content">
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="" />
            </Link>
          </div>
          <div className="nav">
            <ul>
              <Link to="aboutus">
                <li>About Us</li>
              </Link>
              <li>
                Our Businesses
                <div className="libus">
                  <div className="content">
                    <p>
                      <Link to="/engineering"> Synoford Engineering</Link>
                    </p>
                    <p>
                      <Link to="/homes">Synoford Homes</Link>
                    </p>
                    <p>
                      <Link to="/tofro">TO-FRO haulage & Logistics</Link>
                    </p>
                  </div>
                </div>
              </li>
              <Link to="/sustainability">
                <li> Sustainability</li>
              </Link>

              <li>
                Gallery
                <div className="libus">
                  <div className="content">
                    <p>
                      <Link to="/synofordgallery"> Synoford Gallery</Link>
                    </p>
                    <p>
                      <Link to="/tofrogallery">Tofro Gallery</Link>
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div className="button">
            <Link to="/contact"> Contact Us</Link>
          </div>

          <div className="menu" onClick={handleToggle}>
            <img src={menu} alt="" />
          </div>
        </div>
      </div>

      {/* mobile view  */}
      <div className={isActive ? "active" : "mobile"}>
        <div className="three">
          <div onClick={handleToggle} className="logo">
            <Link to="/">
              <img src={logo} alt="" />
            </Link>
          </div>

          <div className="menu" onClick={handleToggle}>
            <img src={menu2} alt="" />
          </div>
        </div>

        <div className="products" onClick={handleToggle}>
          <Link to="/aboutus"> About us</Link>
        </div>

        <div className="products" onClick={handleToggle}>
          <Link to="/engineering">Synoford Engineering</Link>
        </div>

        <div className="products" onClick={handleToggle}>
          <Link to="/homes">Synoford Homes</Link>
        </div>

        <div className="products" onClick={handleToggle}>
          <Link to="/tofro">TO-FRO haulage & Logistics</Link>
        </div>
        <div className="products" onClick={handleToggle}>
          <Link to="/sustainability">Sustainability </Link>
        </div>

        <div className="products" onClick={handleToggle}>
          <Link to="/synofordgallery"> Synoford Gallery</Link>
        </div>
        <div className="products" onClick={handleToggle}>
          <Link to="/tofrogallery">Tofro Gallery</Link>
        </div>
        <div className="products" onClick={handleToggle}>
          <Link to="/contact"> Contact us</Link>
        </div>
      </div>
    </div>
  );
}
