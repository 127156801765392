import React from "react";
import eight from "../../images/tofro/eight.jpg";
import one from "../../images/tofro/two.jpg";
import three from "../../images/tofro/thirteen.jpg";
import four from "../../images/tofro/eleven.jpg";
import five from "../../images/tofro/five.png";
import "./tofro.css";
import { Link } from "react-router-dom";

export default function Tofro() {
  return (
    <div className="tofro">
      <div class="container">
        <div class="first">
          <div class="img-wrapper">
            <img alt="" src={eight} />
          </div>
          <div className="width">
            <div class="banner">
              <h1>TO FRO LOGISTICS</h1>
              <p></p>
            </div>
          </div>
        </div>

        <section class="about-us" id="about">
          <div data-aos="fade-up" class="section-header">
            <h1 class="section-heading">About Us</h1>
            <div class="underline"></div>
          </div>
          <div class="services">
            <div data-aos="fade-up" class="service">
              <div class="service-header">
                <i class="fas fa-pen-nib"></i>
                <h3>Supply chain.</h3>
              </div>
              <p class="service-text">
                We believe that logistics is not just about moving goods from
                one place to another, but it is about managing the entire supply
                chain.
              </p>
            </div>
            <div data-aos="fade-up" class="service">
              <div class="service-header">
                <i class="fas fa-paint-roller"></i>
                <h3>Partnerships.</h3>
              </div>
              <p class="service-text">
                We have a national network of partners and affiliates that help
                us deliver high-quality logistics services to clients across
                various industries, including healthcare, retail, manufacturing,
                and more. Our partners are chosen based on their strength and
                capabilities, ensuring that we provide the best possible service
                to our clients
              </p>
            </div>
            <div data-aos="fade-up" class="service">
              <div class="service-header">
                <i class="fas fa-pencil-alt"></i>
                <h3>Technology</h3>
              </div>
              <p class="service-text">
                We are committed to leveraging the latest technology to provide
                efficient and cost-effective logistics solutions. We use
                advanced transportation management systems, warehouse management
                systems, and supply chain analytics to optimize our clients'
                logistics processes
              </p>
            </div>
            <div data-aos="fade-up" class="service">
              <div class="service-header">
                <i class="fas fa-paint-brush"></i>
                <h3>Sustainability.</h3>
              </div>
              <p class="service-text">
                We understand the importance of sustainability in today's world,
                and we take our responsibility to the environment seriously. We
                are committed to reducing carbon emissions, minimizing waste,
                and using eco-friendly packaging whenever possible.
              </p>
            </div>
            <div data-aos="fade-up" class="service">
              <div class="service-header">
                <i class="fas fa-ruler-combined"></i>
                <h3>Timely Delivery</h3>
              </div>
              <p class="service-text">
                We are committed to providing cost effective Logistics solutions
                for every business in Nigeria. With faster and technology
                powered delivery services, we offer complete flexibility that
                are timely and reliable.
              </p>
            </div>
            <div data-aos="fade-up" class="service">
              <div class="service-header">
                <i class="far fa-building"></i>
                <h3>Safety and Protection</h3>
              </div>
              <p class="service-text">
                To FRO will play a major role of packing your goods safely
                before they are transported. We always pack the goods in
                different safety packages to ensure that the goods arrive in
                good condition.
              </p>
            </div>
            <div data-aos="fade-up" class="about-us-img-wrapper">
              <img alt="" src={five} />
            </div>
          </div>
        </section>

        <section class="team1">
          <div data-aos="fade-up" class="section-header">
            <h1 class="section-heading">Our Vehicles</h1>
            <div class="underline"></div>
          </div>
          <div class="cards-wrapper">
            <div data-aos="fade-up" class="card">
              <div class="card-img-wrapper">
                <img src={three} alt="CEO" />
              </div>
              <div class="card-info">
                <h2>Semi Trailer </h2>
                <h3>Truck</h3>

                <Link to="/tofrogallery">
                  <div className="aboutus">
                    <button>Check More</button>{" "}
                  </div>
                </Link>
              </div>
            </div>
            <div data-aos="fade-up" class="card">
              <div class="card-img-wrapper">
                <img src={four} alt="Designer" />
              </div>
              <div class="card-info">
                <h2>Straight </h2>
                <h3>Truck</h3>

                <Link to="/tofrogallery">
                  <div className="aboutus">
                    <button>Check More</button>{" "}
                  </div>
                </Link>
              </div>
            </div>
            <div data-aos="fade-up" class="card">
              <div class="card-img-wrapper">
                <img src={one} alt="Architect" />
              </div>
              <div class="card-info">
                <h2>Tail Lift </h2>
                <h3>Truck</h3>

                <Link to="/tofrogallery">
                  <div className="aboutus">
                    <button>Check More</button>{" "}
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
