import React from "react";
import "./aboutus.css";
import { Link } from "react-router-dom";

export default function Aboutus() {
  return (
    <div className="aboutus">
      <div className="width">
        <p>About Us</p>
        <p>
          Synoford Homes is the finishing and Interior Décor arm of Synoford
          Engineering Nig. Ltd. We offer full finishing services ranging from
          planning, designing, construction of spaces, and renovation of
          existing spaces.
        </p>
        <Link to="/aboutus">
          <button>Read More</button>
        </Link>
      </div>
    </div>
  );
}
