import React from "react";
import "./homes.css";
import one from "../../images/home/one.jpg";
import Banking from "../../component/banking/Banking";
import two from "../../images/home/two.jpg";
import four from "../../images/home/four.jpg";
import three from "../../images/home/three.jpg";

export default function Homes() {
  return (
    <div className="homes">
      <div className="engineering">
        <div className="bodyreuse">
          <div className="width">
            <div className="reuse">
              <div className="text bw">
                <p>Synoford Homes</p>
                Is the finishing and Interior Décor arm of Synoford Engineering
                Nig. Ltd. We offer full finishing services ranging from
                planning, designing, construction of spaces, and renovation of
                existing spaces.
              </div>
              <div className="pic">
                <img src={one} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="oilreverse">
          <div className="width">
            <Banking />

            <div className="reuse ">
              <div className="text bw">
                <p>PARTNERSHIP</p>
                We have strong strategic alliances with our parent
                construction/engineering company as well as other leading
                architectural construction such as M&E companies in order to
                keep providing top-notch projects to our steadily expanding
                clientele. Through this cooperation, we can make sure that every
                member of our team is regularly exposed to the best practices
                from around the world, which they will implement on each
                project.
              </div>
              <div className="pic">
                <img src={two} alt="" />
              </div>
            </div>

            <div className="reuse reverse">
              <div className="text bw">
                <p>PROCEDURE</p>
                We work closely with our clients to understand their specific
                needs and preferences, and we help them select properties that
                meet their criteria. Once a client has identified a property
                they are interested in, we arrange for an inspection and provide
                a detailed report on the property's condition. If the client
                decides to move forward with the purchase, we help them
                formalize the agreement by signing a contract and transfer the
                property to their name. Our team is dedicated to providing
                exceptional service throughout the entire process and ensuring
                that our clients are fully satisfied with their purchase
              </div>
              <div className="pic">
                <img src={three} alt="" />
              </div>
            </div>

            <div className="reuse ">
              <div className="text bw">
                <p>Professionalism</p>
                We pride ourselves on our professional approach to selling or
                renting properties. Our team is dedicated to exceptional
                service, open communication, and using best practices in the
                industry. We aim to make the process smooth and stress-free for
                our clients and build long-term relationships with personalized
                service.
              </div>
              <div className="pic">
                <img src={four} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
