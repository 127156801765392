import "./App.css";
import { HashRouter, Routes, Route } from "react-router-dom";
import Footer from "./component/footer/Footer";
import Home from "./pages/home/Home";
import Header from "./component/header/Header";
import About from "./pages/about/About";
import ScrollToTop from "./component/ScrollToTop";
import Contactus from "./pages/contact/Contactus";
import Sustainability from "./pages/sustainability/Sustainability";
import Tofro from "./pages/tofor/Tofro";
import Engineering from "./pages/eng/Engineering";
import Homes from "./pages/homes/Homes";
import Team from "./pages/team/Team";
import Gallery from "./pages/gallery/Gallery";
import Gallery2 from "./pages/gallery/Gallery2";

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/aboutus" element={<About />} />
          <Route path="/contact" element={<Contactus />} />
          <Route path="/sustainability" element={<Sustainability />} />
          <Route path="/team" element={<Team />} />
          <Route path="/tofro" element={<Tofro />} />
          <Route path="/engineering" element={<Engineering />} />
          <Route path="/homes" element={<Homes />} />
          <Route path="/synofordgallery" element={<Gallery />} />
          <Route path="/tofrogallery" element={<Gallery2 />} />
        </Routes>

        <Footer />
      </HashRouter>
    </div>
  );
}

export default App;
