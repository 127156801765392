import React from "react";
import two from "../../images/homepage/4 (1).png";
import "./best.css";

export default function Best() {
  return (
    <div className="best">
      <div className="width">
        <section id="the-best">
          <div class="flex container">
            <img src={two} alt="prop-1" />
            <div>
              <h2>PROJECTS EXECUTED </h2>
              <p class="large-paragraph">
                We deliver innovative solutions for value-driven project
                planning and execution.
              </p>{" "}
              <br />
              <p>
                We have successfully executed several projects while others are
                still under construction
              </p>
              <ul>
                <li>ROAD/DRAINS</li>
                <li>COMMERCIAL DEVELOPMENT</li>
                <li>PRIVATE DEVELOPMENT</li>
                <li>ELECTRIFICATION</li>
                <li>INFRASTRUCTURE</li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
