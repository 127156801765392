import React from "react";
import "./gallery.css";
import one from "../../images/syno images/1.png";
import two from "../../images/syno images/2.png";
import three from "../../images/syno images/3.png";
import four from "../../images/syno images/4.png";
import five from "../../images/syno images/5.jpg";
import six from "../../images/syno images/6.png";
import seven from "../../images/syno images/7.png";
import eight from "../../images/syno images/8.png";
import twentytwo from "../../images/syno images/22.png";
import twentythree from "../../images/syno images/23.jpg";
import twentyfour from "../../images/syno images/24.jpg";
import twentyfive from "../../images/syno images/25.jpg";
import twentysix from "../../images/syno images/26.png";
import twentyseven from "../../images/syno images/27.jpg";
import twentyeight from "../../images/syno images/28.jpg";
import twentynine from "../../images/syno images/29.jpg";
import thirty from "../../images/syno images/30.png";
import thirtyone from "../../images/syno images/31.jpg";
import thirtytwo from "../../images/syno images/32.jpg";
import thirtythree from "../../images/syno images/33.png";
import thirtysix from "../../images/syno images/36.jpg";
import first from "../../images/syno images/photo_2023-04-28_17-49-17.jpg";
import sec from "../../images/syno images/photo_2023-04-28_17-49-21.jpg";
import fourth from "../../images/syno images/photo_2023-04-28_17-49-36.jpg";
import third from "../../images/syno images/photo_2023-04-28_17-49-25.jpg";
import fifth from "../../images/syno images/photo_2023-04-28_17-49-39.jpg";
import sixth from "../../images/syno images/photo_2023-04-28_17-49-43.jpg";
import seventh from "../../images/syno images/photo_2023-04-28_17-59-08.jpg";
import nine from "../../images/syno images/photo_2023-04-28_17-59-24.jpg";
import e1 from "../../images/syno images/new/Esoteric Heights.jpeg";
import e2 from "../../images/syno images/new/Esoteric Heights (2).jpeg";
import e3 from "../../images/syno images/new/Esoteric Heights (3).jpeg";
import e4 from "../../images/syno images/new/Esoteric Heights (3).jpeg";
import e5 from "../../images/syno images/new/Esoteric Heights (4).jpeg";
import e6 from "../../images/syno images/new/Esoteric Heights (5).jpeg";
import a1 from "../../images/syno images/new/Ashebrooke Apartments.jpeg";
import a2 from "../../images/syno images/new/Ashebrooke Apartments (2).jpeg";
import a3 from "../../images/syno images/new/Ashebrooke Apartments (3).jpeg";
import a4 from "../../images/syno images/new/Ashebrooke Apartments (4).jpeg";
import a5 from "../../images/syno images/new/Ashebrooke Apartments (5).jpeg";
import a6 from "../../images/syno images/new/Ashebrooke Apartments (6).jpeg";
import m1 from "../../images/syno images/new/mimch.jpeg";
import m2 from "../../images/syno images/new/mimch (2).jpeg";
import m3 from "../../images/syno images/new/mimch (3).jpeg";
import m4 from "../../images/syno images/new/mimch (4).jpeg";
import m5 from "../../images/syno images/new/mimch (10).jpeg";
import m6 from "../../images/syno images/new/mimch (5).jpeg";
import m7 from "../../images/syno images/new/mimch (6).jpeg";
import m8 from "../../images/syno images/new/mimch (7).jpeg";
import m9 from "../../images/syno images/new/mimch (8).jpeg";
import m10 from "../../images/syno images/new/mimch (9).jpeg";
import n1 from "../../images/syno images/new/any.jpeg";
import n2 from "../../images/syno images/new/any (2).jpeg";
import n3 from "../../images/syno images/new/any (3).jpeg";
import n4 from "../../images/syno images/new/any (4).jpeg";
import n5 from "../../images/syno images/new/any (5).jpeg";
import n6 from "../../images/syno images/new/any (6).jpeg";
import n7 from "../../images/syno images/new/any (7).jpeg";
import n8 from "../../images/syno images/new/any (8).jpeg";
import n9 from "../../images/syno images/new/any (9).jpeg";
import n10 from "../../images/syno images/new/any (10).jpeg";
import n11 from "../../images/syno images/new/any (11).jpeg";
import n12 from "../../images/syno images/new/any (12).jpeg";
import n13 from "../../images/syno images/new/any (13).jpeg";
import n14 from "../../images/syno images/new/any (14).jpeg";
import n15 from "../../images/syno images/new/any (15).jpeg";
import n16 from "../../images/syno images/new/any (16).jpeg";
import n17 from "../../images/syno images/new/any (17).jpeg";
import aa1 from "../../images/syno images/new/new/a.jpeg";
import aa2 from "../../images/syno images/new/new/a (2).jpeg";
import aa3 from "../../images/syno images/new/new/a (3).jpeg";
import aa4 from "../../images/syno images/new/new/a (4).jpeg";
import aa5 from "../../images/syno images/new/new/a (5).jpeg";
import aa6 from "../../images/syno images/new/new/a (6).jpeg";
import aa7 from "../../images/syno images/new/new/a (7).jpeg";
import aa8 from "../../images/syno images/new/new/a (8).jpeg";
import aa9 from "../../images/syno images/new/new/a (9).jpeg";
import aa10 from "../../images/syno images/new/new/a (10).jpeg";
import aa11 from "../../images/syno images/new/new/a (11).jpeg";
import aa12 from "../../images/syno images/new/new/a (12).jpeg";
import aa13 from "../../images/syno images/new/new/a (13).jpeg";
import aa14 from "../../images/syno images/new/new/a (14).jpeg";
import aa15 from "../../images/syno images/new/new/a (15).jpeg";
import aa16 from "../../images/syno images/new/new/a (16).jpeg";
import aa17 from "../../images/syno images/new/new/a (17).jpeg";
import aa18 from "../../images/syno images/new/new/a (18).jpeg";
import aa19 from "../../images/syno images/new/new/a (19).jpeg";
import aa20 from "../../images/syno images/new/new/a (20).jpeg";
import aa21 from "../../images/syno images/new/new/a (21).jpeg";
import aa22 from "../../images/syno images/new/new/a (22).jpeg";
import aa23 from "../../images/syno images/new/new/a (23).jpeg";
import aa24 from "../../images/syno images/new/new/a (24).jpeg";
import aa25 from "../../images/syno images/new/new/a (25).jpeg";
import aa26 from "../../images/syno images/new/new/a (26).jpeg";
import aa27 from "../../images/syno images/new/new/a (27).jpeg";
import aa28 from "../../images/syno images/new/new/a (28).jpeg";
import aa29 from "../../images/syno images/new/new/a (29).jpeg";
import aa30 from "../../images/syno images/new/new/a (30).jpeg";
import aa31 from "../../images/syno images/new/new/a (31).jpeg";
import aa32 from "../../images/syno images/new/new/a (32).jpeg";
import aa33 from "../../images/syno images/new/new/a (33).jpeg";
import aa34 from "../../images/syno images/new/new/a (34).jpeg";
import aa35 from "../../images/syno images/new/new/a (35).jpeg";
import aa36 from "../../images/syno images/new/new/a (36).jpeg";
import aa37 from "../../images/syno images/new/new/a (37).jpeg";
import aa38 from "../../images/syno images/new/new/a (38).jpeg";
import aa39 from "../../images/syno images/new/new/a (39).jpeg";
import aa40 from "../../images/syno images/new/new/a (40).jpeg";
import aa41 from "../../images/syno images/new/new/a (41).jpeg";
import aa42 from "../../images/syno images/new/new/a (42).jpeg";

export default function Gallery() {
  return (
    <div className="gallery">
      <div className="width padding">
        <div class="about portfolio section-header">
          <h1 class="section-heading">SYNOFORD HOMES</h1>

          <div class="work">
            <h3> Esoteric Heights</h3>
            <div class="container5">
              <img alt="building" src={e1} />
              <img alt="building" src={e2} />
              <img alt="building" src={e3} />
              <img alt="building" src={e4} />
              <img alt="building" src={e5} />
              <img alt="building" src={e6} />
              {/* <img alt="building" src={seven} /> */}
              {/* <img alt="building" src={eight} /> */}
              {/* <img alt='building' src={sixteen}/> */}
            </div>
          </div>

          <div class="work">
            <h3> Ashebrooke Apartments</h3>
            <div class="container5">
              <img alt="building" src={a1} />
              <img alt="building" src={a2} />
              <img alt="building" src={a3} />
              <img alt="building" src={a4} />
              <img alt="building" src={a5} />
              <img alt="building" src={a6} />
              {/* <img alt="building" src={seven} /> */}
              {/* <img alt="building" src={eight} /> */}
              {/* <img alt='building' src={sixteen}/> */}
            </div>
          </div>

          <div class="work">
            <h3> Mimshach Apartments</h3>
            <div class="container5">
              <img alt="building" src={m1} />
              <img alt="building" src={m6} />
              <img alt="building" src={m3} />
              <img alt="building" src={m7} />
              <img alt="building" src={m5} />
              <img alt="building" src={m8} />
              <img alt="building" src={m9} />
              <img alt="building" src={m10} />
              <img alt="building" src={m6} />
              <img alt="building" src={m4} />
              <img alt="building" src={m2} />
            </div>
          </div>

          <div class="work">
            <h3>MEGAMOUND ESTATE (IN ITS FINAL PHASE) </h3>
            <div class="container5">
              <img alt="building" src={one} />
              <img alt="building" src={two} />
              <img alt="building" src={three} />
              <img alt="building" src={five} />
              <img alt="building" src={six} />
              <img alt="building" src={seven} />
              <img alt="building" src={eight} />
              {/* <img alt='building' src={sixteen}/> */}
            </div>
          </div>

          <div class="work">
            <h3>KIUMI STREET, LEKKI PHASE 1</h3>
            <div class="container5">
              <img alt="building" src={twentyseven} />
              <img alt="building" src={twentythree} />
              <img alt="building" src={twentyfour} />
              <img alt="building" src={twentyfive} />
              <img alt="building" src={twentytwo} />
              <img alt="building" src={twentysix} />
            </div>
          </div>
          <div class="work">
            <h3>OBA OGBAGBA CLOSE, LEKKI PHASE 1.</h3>
            <div class="container5">
              <img alt="building" src={thirtytwo} />
              <img alt="building" src={thirtythree} />
              <img alt="building" src={thirty} />
              <img alt="building" src={twentyeight} />
              <img alt="building" src={twentynine} />
              <img alt="building" src={thirtyone} />
              <img alt="building" src={thirtysix} />
            </div>
          </div>
          <div class="work">
            <h3>More buildings.</h3>
            <div class="container5">
              <img alt="building" src={first} />
              <img alt="building" src={sec} />
              <img alt="building" src={third} />
              <img alt="building" src={fourth} />
              <img alt="building" src={fifth} />
              <img alt="building" src={sixth} />
              <img alt="building" src={seventh} />
              <img alt="building" src={four} />
              <img alt="building" src={nine} />
            </div>
          </div>

          <div class="work">
            <h3> </h3>
            <div class="container5">
              <img alt="building" src={n1} />
              <img alt="building" src={n2} />
              <img alt="building" src={n3} />
              <img alt="building" src={n4} />
              <img alt="building" src={n5} />
              <img alt="building" src={n6} />
              <img alt="building" src={n7} />
              <img alt="building" src={n8} />
              <img alt="building" src={n9} />
              <img alt="building" src={n10} />
              <img alt="building" src={n11} />
              <img alt="building" src={n12} />
              <img alt="building" src={n13} />
              <img alt="building" src={n17} />
              <img alt="building" src={n15} />
              <img alt="building" src={n16} />
              <img alt="building" src={n14} />
              <img alt="building" src={n9} />
            </div>
          </div>

          <div class="work">
            <h3> </h3>
            <div class="container5">
              <img alt="building" src={aa1} />
              <img alt="building" src={aa2} />
              <img alt="building" src={aa3} />
              <img alt="building" src={aa4} />
              <img alt="building" src={aa5} />
              <img alt="building" src={aa6} />
              <img alt="building" src={aa7} />
              <img alt="building" src={aa8} />
              <img alt="building" src={aa9} />
              <img alt="building" src={aa10} />
              <img alt="building" src={aa11} />
              <img alt="building" src={aa13} />
              <img alt="building" src={aa17} />
              <img alt="building" src={aa15} />
              <img alt="building" src={aa16} />
              <img alt="building" src={aa14} />
              <img alt="building" src={aa9} />
              <img alt="building" src={aa18} />
              <img alt="building" src={aa19} />
              <img alt="building" src={aa20} />
              <img alt="building" src={aa21} />
              <img alt="building" src={aa22} />
              <img alt="building" src={aa23} />
              <img alt="building" src={aa24} />
              <img alt="building" src={aa25} />
              <img alt="building" src={aa26} />
              <img alt="building" src={aa27} />
              <img alt="building" src={aa28} />
              <img alt="building" src={aa29} />
              <img alt="building" src={aa30} />
              <img alt="building" src={aa31} />
              <img alt="building" src={aa38} />
              <img alt="building" src={aa39} />

              <img alt="building" src={aa33} />
              <img alt="building" src={aa34} />
              <img alt="building" src={aa35} />
              <img alt="building" src={aa36} />
              <img alt="building" src={aa37} />
              <img alt="building" src={aa32} />

              <img alt="building" src={aa40} />
              <img alt="building" src={aa41} />
              <img alt="building" src={aa42} />
              <img alt="building" src={aa12} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
