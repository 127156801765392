import React from "react";
import Aboutus from "../../component/aboutus/Aboutus";
import Best from "../../component/best/Best";
import Bodycontent from "../../component/Bodycontent/Bodycontent";
// import Header from "../../component/header/Header";
import Vision from "../../component/vision/Vision";

export default function Home() {
  return (
    <div className="home">
      <Bodycontent />

      <Vision />

      <div className="width">
        <section className="how-it-works">
          <div class="container">
            <h2>How it works</h2>
            <div class="flex">
              <div>
                <span class="fas fa-home"></span>
                <h4>We Meet</h4>
                <p>
                  We start out by sitting down and talking with our clients. We
                  spend a great deal of time making sure we understand exactly
                  what they want
                </p>
              </div>
              <div>
                <span class="fas fa-building"></span>
                <h4>We Work</h4>
                <p>
                  Then we go ahead to turn their imaginations and desires into a
                  workable blueprint.{" "}
                </p>
              </div>
              <div>
                <span class="far fa-handshake"></span>
                <h4>We Deliver</h4>
                <p>
                  Once we have our blueprint, our clients can be rest assured
                  that their projects will be delivered according to their
                  specifications, on budget and on time.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Best />

      <Aboutus />
    </div>
  );
}
